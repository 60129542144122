* {
  box-sizing: border-box;
  font-family: 'Comfortaa', cursive;
  background-image: none;
}

button, a {
  transition: 0.3s;
}

input:focus,
input:focus-visible{
  text-decoration: none;
  list-style: none;
  outline: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0,0,0,0);
  background-color: rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar {
  width: 1px;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background-color: #000;
}

body {
  background-color: #8de7ff;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.btn {
  padding: 15px 50px !important;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.active {
  display: flex;
}

.active-blue {
  background-color: #61dafb;
  color: #fff;
}

.shadow {
  box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
}

.active-menu {
  color: #fff !important;
}

.selected {
  color: #fff !important;
}

.sort-btns li:hover {
  margin-left: 0 !important;
}

.logo-text {
  margin-top: 3px;
}

ol {
  padding-left: 0;
  list-style: none;
  counter-reset: my-awesome-counter;

  li {
    position: relative;
    counter-increment: my-awesome-counter;
    padding-left: 30px;

    &::before {
      position: absolute;
      top: 0px;
      left: 0;
      content: counter(my-awesome-counter) ". ";
      color: #b7f0ff;
    }
  }
}

[disabled] {
  cursor: default !important;
}

[disabled] .fas {
  color: #61dafb;
}

.info h2 {
  padding-bottom: 15px;
}

.btn-admin {
  background-color: #61dafb !important;
  color: #b7f0ff;
}

.active-admin,
.active-create {
  background-color: #61dafb !important;
  color: #000 !important;
}

.active-create .fas {
  color: #fff !important;
}

.red {
  background-color: red;
  animation: blink 0.5s infinite;
}

@keyframes blink {
  from { opacity: 1; }
  to { opacity: 0; }
}

.fas {
  color: #fff;
  font-size: 17px;
}

#list-companies li:hover {
  margin-left: 5px;
  color: #b7f0ff;
}

.fas-btn {
  padding: 2px 20px !important;
}

#list-companies .fas-btn i {
  color: #b7f0ff;
}

#list-companies li .active-btn.fas-btn:hover i {
  color: #fff;
}

.btns {
  margin-top: -5px;
}

.fas-btn {
  padding: 2px 15px !important;
}

/* 1400-1300px */
@media screen and (max-width: 1400px) {

  .fas-btn {
    padding: 2px 10px !important;
  }

}

/* 1200-1300px */
@media screen and (max-width: 1300px) {
  #list-companies {
    width: 40% !important;
  }

  #power-by {
    right: auto !important;
    left: 50%;
    bottom: 80px !important;
    transform: translate(-50%, 0);
    width: 210px;
  }

}

/* 992-1200px */
@media screen and (max-width: 1200px) {
  .logo-text {
    margin-top: 1px !important;
  }

  .search-company {
    width: 25% !important;
  }

  #list-companies {
    width: 45% !important;
  }

  .info ul li {
    width: 95% !important;
  }

}

/* 768-992px */
@media screen and (max-width: 992px) {

  header .btns {
    display: none !important;
  }

  .search-company {
    width: 65% !important;
  }

  .admin-btns {
    display: none !important;
  }

  #list-companies {
    width: 55% !important;
  }

  .sort-btns {
    width: 42px !important;
  }

  .info ul li {
    width: 100% !important;
  }

  .info ul {
    padding: 10px !important;
  }

  .list-box ol li .btns {
    display: none !important;
  }
}

/* 576-768px */
@media screen and (max-width: 768px) {
  html {
    font-size: 14px !important;
  }

  header {
    height: 68px !important;
  }

  .search-company {
    width: 60% !important;
  }

  .fa-times-circle {
    top: 4px !important;
    right: 5px !important;
  }

  .list-box {
    margin: 0 20px !important;
  }

  .list-box ol {
    margin: 5px 0 !important;
  }

  .info h2 {
    padding-bottom: 0 !important;
  }

  .info ul li {
    margin-bottom: 15px !important;
  }

  #built-with {
    transform: translate(-45%, 0) !important;
  }

  #power-by {
    bottom: 72px !important;
    transform: translate(-40%, 0) !important;
  }

  .sort-btns {
    display: none !important;
  }
}

/* 320-576px */
@media screen and (max-width: 576px) {
  .logo img {
    margin-right: 10px !important;
  }

  .search-company {
    display: none !important;
  }

  #built-with {
    width: 185px !important;
  }

  .wrap {
    flex-direction: column !important;
  }

  #list-companies {
    width: 100% !important;
    height: 280px !important;
  }

  .list-box {
    margin: 0 10px !important;
  }

  .list-box ol li {
    padding-left: 30px !important;
  }

  .sort-btns {
    display: flex !important;
    top: 25px !important;
  }

  #information {
    margin-top: 20px !important;
    padding-left: 0 !important;
    width: 100% !important;
    padding-bottom: 100px !important;
  }

  .info ul li {
    padding: 10px !important;
  }

  .info-wrap {
    padding: 10px 0 !important;
  }

  #power-by a {
    color: #fff !important;
  }

  #built-with {
    width: 270px !important;
    transform: translate(-50%, 0) !important;
  }
}
